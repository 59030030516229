<template>
	<main>
		<div class="s5">
			<div class="amap"></div>
			<div class="content">
				<div class=" contact-box">
					<div class="title-box">
						<div class="title">联系我们</div>
						<div class="min-title">我们期待 为您服务</div>
					</div>
					<div class="form-box">
						<div class="in">
							<input type="text" placeholder="您的姓名" v-model="username">
						</div>
						<div class="in">
							<input type="tel" placeholder="您的电话" v-model="phone">
						</div>
						<div class="in">
							<textarea class="scroller" v-model="message" name="message" placeholder="请尽可能详细地输入您的需求，以及方便联系您的时间段"></textarea>
						</div>
						<div class="submit">
							<div class="btn" @click="submit">提交</div>
						</div>
					</div>
				</div>
				<div class=" info-box">
					<div class="title-box">
						<div class="title">联系信息</div>
						<div class="min-title">请直接联系我们，您的信任，我们的坚持与执着，超越无限。</div>
					</div>
					<div class="all">
						<div class="block item">
							<div class="icon"><i class="iconfont icon-shouhuodizhi"></i></div>
							<div class="tit">{{ config.home_address }}</div>
						</div>
						<a href="tel:18874580524" class="block item">
							<div class="icon"><i class="iconfont icon-dianhua"></i></div>
							<div class="tit">{{ config.home_phone }}<br/>(微信同号)</div>
						</a>
						<a href="https://wpa.qq.com/msgrd?v=3&uin=529914815&site=qq&menu=yes" class="block item">
							<div class="icon"><i class="iconfont icon-qq"></i></div>
							<div class="tit">{{ config.home_qq_number }}</div>
						</a>
						<a href="mailto:ydx425@qq.com" class="block item">
							<div class="icon"><i class="iconfont icon-youxiang"></i></div>
							<div class="tit">{{ config.home_email }}</div>
						</a>
					</div>
					<div class="logo"></div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import {Toast} from "vant";
import {checkString} from "@/common/type";
import {message} from "@/api/views";
export default {
	data() {
		return {
			username:"",
			phone:"",
			message:"",
		}
	},
	computed:{
		config(){
			return this.$store.state.views.config;
		}
	},
	methods: {
		submit(){
			var data = {
				username:this.username,
				phone:this.phone,
				message:this.message,
				ua:navigator.userAgent
			}
			if(!this.username){Toast.fail('请输入您的姓名');return false;}
			if(!this.phone){Toast.fail('请输入您的手机号码');return false;}
			if(!checkString(this.phone,'phone')){Toast.fail('请输入正确的手机号码');return false;}
			if(!this.message){Toast.fail('请输入您的需求');return false;}

			message.submit(data).then(()=>{
				Toast.success({
					message:'提交成功,我们会尽快与您联系',
					onClose:()=>{
						this.username = "";
						this.phone = "";
						this.message = "";
					}
				});
			})
		}
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.s5{min-height: 800px;position: relative;
	.amap{position: absolute;left:0;top:0;width: 100%;height:100%;background: url("~@/assets/image/map.png") no-repeat center center;background-size: cover;}
	.amap::after{content:"";display: block;position: absolute;top:0;left:0;width: 100%;height:100%;}
	.content{width: 1200px;position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);background: #fff;display: flex;margin-top: 35px;
		.contact-box{background: $main;color:#fff;padding: 30px;width: 360px;
			.title-box{
				.title{font-size: 28px;}
				.min-title{font-size: 14px;color:#fff;opacity: .8;}
			}
			.form-box{margin-top: 30px;
				.in{font-size: 14px;
					input{display: block;width: 100%;height:46px;line-height: 46px;border: none;border-bottom: 1px solid rgba(255,255,255,.8);background: none;color:#fff;}
					input::placeholder{color:#fff;opacity: .8;}
					textarea{display: block;width: 100%;padding: 10px 0;height:140px;overflow: hidden;overflow-y: auto; background: none;border:none;color:#fff;}
					textarea::placeholder{color:#fff;opacity: .8;}
				}
				.submit{margin-top: 30px;
					.btn{width: 120px;height:42px;line-height: 42px;text-align: center;color:#fff;border: 1px solid #fff;margin: 0 auto;cursor:pointer;}
				}
			}
		}
		.info-box{padding: 30px;flex: 1;position: relative;
			.title-box{text-align: center;
				.title{font-size: 28px;}
				.min-title{font-size: 16px;opacity: .8;margin-top: 20px;}
			}
			.all{display: flex;flex-wrap: wrap;margin-top: 50px;
				.item{width: 25%;padding: 0 15px;
					.icon{width: 50px;height:50px;margin: 0 auto;text-align: center;line-height: 50px;color:$main;
						.iconfont{font-size: 40px;}
					}
					.tit{text-align: center;margin-top: 10px;color:#333;}
				}
			}
			.logo{width: 180px;height:52px;position: absolute;bottom:30px;right:30px;background: url("~@/assets/image/logo.png") no-repeat center center;background-size: contain;}
		}
	}
}
@media screen and (max-width: 1220px) {
	.s5{min-height:m(800px);
		.content{width: calc(100% - 50px);
			.contact-box{background: $main;color:#fff;padding: m(30px);width:m(360px);
				.title-box{
					.title{font-size: 24px;}
					.min-title{font-size: 14px;color:#fff;opacity: .8;}
				}
				.form-box{margin-top: m(30px);
					.in{font-size: 14px;
						input{height:m(46px);line-height:m( 46px);}
						textarea{padding:m(10px) 0;height:m(140px);}
					}
					.submit{margin-top:m(30px);
						.btn{width:m(120px);height:m(42px);line-height:m( 42px);}
					}
				}
			}
			.info-box{padding: m(30px);flex: 1;position: relative;
				.title-box{text-align: center;
					.title{font-size: 24px;}
					.min-title{font-size: 14px;margin-top:m( 20px);}
				}
				.all{margin-top: m(50px);
					.item{padding: 0 m(15px);
						.icon{width:m(50px);height:m(50px);line-height:m(50px);
							.iconfont{font-size:m( 40px);}
						}
						.tit{margin-top:m( 10px);font-size: 13px;}
					}
				}
				.logo{width:m(180px);height:m(52px);bottom:m(30px);right:m(30px);}
			}
		}
	}
}
@media screen and (max-width:768px) {
	.s5{min-height: x(1600px);
		.content{width: calc(100% - 30px);flex-direction: column;
			.contact-box{background: $main;color:#fff;padding: m(30px);width:100%;
				.title-box{
					.title{font-size: 24px;}
					.min-title{font-size: 14px;color:#fff;opacity: .8;}
				}
				.form-box{margin-top: m(30px);
					.in{font-size: 14px;
						input{height:m(60px);line-height:m( 60px);}
						textarea{padding:m(10px) 0;height:m(140px);}
					}
					.submit{margin-top:m(30px);
						.btn{width:m(120px);height:m(42px);line-height:m( 42px);}
					}
				}
			}
			.info-box{padding: x(30px);flex: 1;position: relative;
				.title-box{text-align: center;
					.title{font-size: 24px;}
					.min-title{font-size: 14px;margin-top:x( 20px);}
				}
				.all{margin-top: x(50px);display: block;
					.item{padding: 0;float: none;width: 100%;overflow:hidden;line-height:x( 40px);margin-top: 8px;
						.icon{width:x(50px);height:x(50px);line-height:x(50px);float:left;
							.iconfont{font-size:x( 40px);}
						}
						.tit{margin-top:0;margin-left: 5px; font-size: 13px;float:left;text-align: left;}
					}
				}
				.logo{width:m(180px);height:m(52px);bottom:m(30px);right:m(30px);display: none;}
			}
		}
	}
}
</style>

